import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private baseUrl = 'http://api.quotation.connextphilippines.com/api';
  constructor(
    private http: HttpClient,
    private token: TokenService
  ) { }

  user() {
    const token = this.token.get();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS'
    });
    return this.http.post(`${this.baseUrl}/me?token=` + token, { headers });
  }

  postAgentInfo(id) {
    const token = this.token.get();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS'
    });
    return this.http.post(`${this.baseUrl}/getuser?token=` + token + `&id=` + id, { headers });
  }


  refresh() {
    const token = this.token.get();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.baseUrl}/refresh?token=` + token, { headers });
  }

  handle(name, email, branch_id) {
    this.set(name, email, branch_id);
  }

  set(full_name, email, branch_id) {
    localStorage.setItem('full_name', full_name);
    localStorage.setItem('branch_id', branch_id);
    localStorage.setItem('email', email);
  }

  setName(name) {
    localStorage.setItem('name', name);
  }

  getName() {
    return localStorage.getItem('name');
  }

  getRole() {
    return localStorage.getItem('role');
  }


  getEmail() {
    return localStorage.getItem('email');
  }

  getBranch() {
    return localStorage.getItem('branch_id');
  }

  removeProfile() {
    localStorage.removeItem('full_name');
    localStorage.removeItem('agent_id');
    localStorage.removeItem('agent_type');
    localStorage.removeItem('branch_id');
  }
}
