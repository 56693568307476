import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { StatusService } from '../services/status.service';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';

declare const $: any;


// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Home',
    type: 'link',
    icontype: 'dashboard'
},
{
    path: '/billing',
    title: 'Bill',
    type: 'link',
    icontype: 'assignment'
},
{
    path: '/quotation',
    title: 'Quotation',
    type: 'link',
    icontype: 'account_balance'
},
{
    path: '/company',
    title: 'Company',
    type: 'link',
    icontype: 'account_balance'
},
{
    path: '/branch',
    title: 'Branch',
    type: 'link',
    icontype: 'note_add'
},
{
    path: '/admin-user',
    title: 'Users',
    type: 'link',
    icontype: 'account_box'
},
{
    path: '/agent',
    title: 'Add RMO',
    type: 'link',
    icontype: 'account_box'
},
{
    path: '/encoder',
    title: 'Add Data Encoder',
    type: 'link',
    icontype: 'account_box'
},
{
    path: '/accounting',
    title: 'Request for Approval',
    type: 'link',
    icontype: 'face'
},
// {
//     path: '/components',
//     title: 'List',
//     type: 'sub',
//     icontype: 'book',
//     collapse: 'components',
//     children: [
//         { path: 'buttons', title: 'Company', ab: 'C' },
//         { path: 'grid', title: 'Branches', ab: 'B' },
//         { path: 'grid', title: 'Branch Admin', ab: 'BA' },
//     ]
// },
// {
//     path: '/components',
//     title: 'Buttons',
//     type: 'sub',
//     icontype: 'chrome_reader',
//     collapse: 'components',
//     children: [
//         { path: 'buttons', title: 'Buttons', ab: 'B' },
//         { path: 'grid', title: 'Grid System', ab: 'GS' },
//         { path: 'panels', title: 'Panels', ab: 'P' },
//         { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
//         { path: 'notifications', title: 'Notifications', ab: 'N' },
//         { path: 'icons', title: 'Icons', ab: 'I' },
//         { path: 'typography', title: 'Typography', ab: 'T' }
//     ]
// },

// {
//     path: '/forms',
//     title: 'Forms',
//     type: 'sub',
//     icontype: 'content_paste',
//     collapse: 'forms',
//     children: [
//         { path: 'regular', title: 'Regular Forms', ab: 'RF' },
//         { path: 'extended', title: 'Extended Forms', ab: 'EF' },
//         { path: 'validation', title: 'Validation Forms', ab: 'VF' },
//         { path: 'wizard', title: 'Wizard', ab: 'W' }
//     ]
// }, {
//     path: '/tables',
//     title: 'Tables',
//     type: 'sub',
//     icontype: 'grid_on',
//     collapse: 'tables',
//     children: [
//         { path: 'regular', title: 'Regular Tables', ab: 'RT' },
//         { path: 'extended', title: 'Extended Tables', ab: 'ET' },
//         { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' }
//     ]
// }, {
//     path: '/maps',
//     title: 'Maps',
//     type: 'sub',
//     icontype: 'place',
//     collapse: 'maps',
//     children: [
//         { path: 'google', title: 'Google Maps', ab: 'GM' },
//         { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
//         { path: 'vector', title: 'Vector Map', ab: 'VM' }
//     ]
// }, {
//     path: '/widgets',
//     title: 'Widgets',
//     type: 'link',
//     icontype: 'widgets'

// }, {
//     path: '/charts',
//     title: 'Charts',
//     type: 'link',
//     icontype: 'timeline'

// }, {
//     path: '/calendar',
//     title: 'Calendar',
//     type: 'link',
//     icontype: 'date_range'
// }, {
//     path: '/pages',
//     title: 'Pages',
//     type: 'sub',
//     icontype: 'image',
//     collapse: 'pages',
//     children: [
//         { path: 'pricing', title: 'Pricing', ab: 'P' },
//         { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
//         { path: 'login', title: 'Login Page', ab: 'LP' },
//         { path: 'register', title: 'Register Page', ab: 'RP' },
//         { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
//         { path: 'user', title: 'User Page', ab: 'UP' }
//     ]
// }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public fullname = null;
    public menuItems: any[];
    ps: any;

    constructor(
        private status: StatusService,
        private router: Router,
        private token: TokenService,
        private user: UserService,
    ) { }

    ngOnInit() {
        this.onloadPage();
        this.getStatus();
    }


    getStatus() {
        return this.user.user().subscribe(
            response => this.handleUserInfo(response),
            error => this.getLogout(error)
        );
    }

    handleUserInfo(user) {
        console.log('user', user.id);
        localStorage.setItem('agent_type', user.role_type);
        this.getAgentInfo(user.id);
        this.handleAgentInfo(user);

        if (user.role_type !== 'admin') {
            this.getLogout('not an admin');
        }

    }

    getAgentInfo(id) {
        console.log(id);
        return this.user.postAgentInfo(id).subscribe(
            response => this.handleAgentInfo(response),
            // error => this.getLogout(error),
        );
    }

    async handleAgentInfo(data) {

        localStorage.setItem('full_name', data[0].full_name);
        localStorage.setItem('branch_id', data[0].branch_id);
        localStorage.setItem('agent_id', data[0].id);
        this.fullname = await localStorage.getItem('full_name');

    }

    getLogout(error) {
        console.log('error', error);
        this.token.remove();
        this.user.removeProfile();
        this.status.changeAuthStatus(true);
        return this.router.navigateByUrl('/auth/login');
    }


    onloadPage() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
