import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { BillingComponent } from './billing/billing.component';
import { CompanyComponent } from './company/company.component';
import { BranchComponent } from './branch/branch.component';
import { BranchAdminComponent } from './branch-admin/branch-admin.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            },
            {
                path: '',
                loadChildren: './billing/billing.module#BillingModule'
            },
            {
                path: '',
                loadChildren: './quotation/quotation.module#QuotationModule'
            },
            {
                path: '',
                loadChildren: './company/company.module#CompanyModule'
            },
            {
                path: '',
                loadChildren: './branch/branch.module#BranchModule'
            },
            {
                path: 'admin-user',
                loadChildren: './users/users.module#UsersModule'
            },
            {
                path: '',
                loadChildren: './user-agent/user-agent.module#UserAgentModule'
            },
            {
                path: '',
                loadChildren: './encoder/encoder.module#EncoderModule'
            },
            {
                path: '',
                loadChildren: './accounting-dashboard/accounting-dashboard.module#AccountingDashboardModule'
            },
            {
                path: 'components',
                loadChildren: './components/components.module#ComponentsModule'
            },
            {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            },
            {
                path: 'tables',
                loadChildren: './tables/tables.module#TablesModule'
            },
            {
                path: 'maps',
                loadChildren: './maps/maps.module#MapsModule'
            },
            {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            },
            {
                path: 'charts',
                loadChildren: './charts/charts.module#ChartsModule'
            },
            {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            },
            {
                path: '',
                loadChildren: './userpage/user.module#UserModule'
            },
            {
                path: '',
                loadChildren: './timeline/timeline.module#TimelineModule'
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'auth',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
